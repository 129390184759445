import { Config, ConfigEnvironment } from './ConfigType';

export const config: Config = {
  env: ConfigEnvironment.PRODUCTION,
  release: '73adb4f5343e97bb87a9017454ff06e6354a7b03',
  appProtocol: 'fym',
  isDebug: false,
  googlePlayStoreDisabled: false,
  sentryDSN: 'https://7bab568d62864af39ab6df3d266ea9a7@sentry.io/2709454',
  domain: 'https://freeyourmusic.com',
  smartlinksDomain: 'https://smartlinksapp.com',
  fymAPI: '/api_api/',
  fymServerAPI: 'https://api.freeyourmusic.com/',
  gTagID: 'G-WN5JDNXJQ4',
  tiktokPixelID: 'CL5N03BC77UE2MBNNQTG',
  imgixHost: 'stamp.imgix.net',
  imgixDynamicHost: 'fymdynamic.imgix.net',
  strapi: {
    url: 'https://cms.freeyourmusic.com',
    token:
      'b872960b0d6bb1a700623e2df33075f30c12d4e5bf94f2073dc654fdf2ca624cce29f227ae7d81d4983742f8da30355e7efc7b44e58e41ddcd57b43721ea2d963aea53608e0f826c8f241ca710652d85d4342b01a6b26da0b5dcb7c0674c2f7573429b38e333098a24df73bcbcd77a797d5449025765d2c3a604672846e84311'
  },
  plausible: {
    siteId: 'freeyourmusic.com',
    url: 'https://plausible.freeyourmusic.com/'
  },
  commentBoxId: '5698665872097280-proj',
  defaultProductPrice: {
    amount: '11.99',
    currency: 'EUR'
  },
  defaultAppLicencePrice: {
    amount: '11.99',
    currency: 'EUR'
  },
  defaultLifetimePrice: {
    amount: '199.99',
    currency: 'EUR'
  },
  defaultYearlyPlanPrice: {
    amount: '39.99',
    currency: 'EUR'
  },
  defaultQuarterlyPlanPrice: {
    amount: '14.99',
    currency: 'EUR'
  },
  blackFriday: {
    coupon: `BF${new Date().getFullYear()}`,
    discountValue: '30',
    startsAt: new Date(new Date().getFullYear(), 10, 20, 0, 0, 0, 0),
    endsAt: new Date(new Date().getFullYear(), 10, 30, 0, 0, 0, 0) // month is 0-11 and days are 1-31, fuck JS
  },
  anniversary: {
    startsAt: new Date(new Date().getFullYear(), 6, 5, 0, 0, 0, 0),
    endsAt: new Date(new Date().getFullYear(), 6, 8, 0, 0, 0, 0) // month is 0-11 and days are 1-31, fuck JS
  }
};
